<template>
	<aside class="u-transmatico" v-if="props.publication === 'swp' || props.publication === 'moz'">
		<div class="transmaticoBox" :data-transmatico-region="getRegion(region)"></div>
	</aside>
</template>

<script lang="ts" setup>
	import { onMounted } from "vue";
	import { useShowAds } from "~/composables/useShowAds";

	const props = defineProps<{
		region: string;
		publication: string;
	}>();

	const isTransmaticoScriptLoaded = useIsTransmaticoLoaded();
	const showAds = await useShowAds();

	function getRegion(region: string) {
		const regionNameMapping: any = {
			ulm: "ulm-neu-ulm",
			"neu-ulm": "ulm-neu-ulm",
			angermuende: "uckermark",
			bad_freienwalde: "maerkisch_oderland",
			beeskow: "oder_spree",
			bernau: "barnim",
			brandenburg_havel: "brandenburg_havel",
			eberswalde: "barnim",
			eisenhuettenstadt: "oder_spree",
			erkner: "oder_spree",
			frankfurt_oder: "oder_spree",
			fuerstenwalde: "oder_spree",
			gransee: "oberhavel",
			hennigsdorf: "oberhavel",
			neuruppin: "ostprignitz_ruppin",
			oranienburg: "oberhavel",
			rathenow: "brandenburg_havel",
			schwedt: "uckermark",
			seelow: "maerkisch_oderland",
			strausberg: "maerkisch_oderland",
		};

		return regionNameMapping[region] || region;
	}

	let transmaticoScript: string = "";

	onMounted(() => {
		if (!isTransmaticoScriptLoaded.value) {
			if (props.region) {
				if (props.publication === "swp") {
					transmaticoScript = `https://backoffice.transmatico.com/get?token=g1pDh6Ssx95FxhqYN0lyz8n69lqtMW5n&service=articleteasers&schema=wide-bar&selector=.transmaticoBox&placement=append&category=${getRegion(props.region)}`;
				} else if (props.publication === "moz") {
					transmaticoScript = `https://backoffice.transmatico.com/get/?token=e45a87c8150da06004ff7e01bd2940a0&domain=sonderthemen.moz.de&block-index=W&block-name=CONTENT&service=inwidget&scriptid=trm-widget-mmTTJ5fu7-9hT7ctJ2GD-9&selector=.transmaticoBox&category=${getRegion(props.region)}`;
				}
			}
			if (window.isCMPLoaded) {
				console.log("Das Skript wird geladen");
				triggerTransmaticoScript();
			} else {
				console.log("Die event handler werden registriert");
				document.addEventListener("cmp.consentDataSet", triggerTransmaticoScript)
			}
			document.addEventListener("cmp.consentDataUpdate", triggerTransmaticoScript);
		}
	});

	function triggerTransmaticoScript() {
		if (showAds.getShowAds().value && !isTransmaticoScriptLoaded.value) {
			isTransmaticoScriptLoaded.value = true;
			useHead({
				script: [{ src: transmaticoScript, async: true }],
			});
			isTransmaticoScriptLoaded.value = true;
		}
	}

	// SMART ADS Einbindung - ohne gtm
	// let transmaticoScript: string[] = [];

	// if (props.publication === 'swp') {
	//     transmaticoScript = [
	//         "https://trmads.eu/get?token=g1pDh6Ssx95FxhqYN0lyz8n69lqtMW5n&ptoken=i3s8af8qasdg245frnuvw4gy0v4bqs2f66iwbnfd",
	//         "https://trmads.eu/get?token=g1pDh6Ssx95FxhqYN0lyz8n69lqtMW5n&ptoken=6ek6ljudru8a3umtrr77duyyfkgi2b6y16w4gd4y",
	//         "https://trmads.eu/get?token=g1pDh6Ssx95FxhqYN0lyz8n69lqtMW5n&ptoken=f9dt2azkd7mzd7k91tcmp0mfzlib5k377ghormq0"
	//     ];
	// } else if (props.publication === 'moz') {
	//     transmaticoScript = [
	//         "https://trmads.eu/get?token=e45a87c8150da06004ff7e01bd2940a0&ptoken=i3s8af8qasdg245frnuvw4gy0v4bqs2f66iwbnfd",
	//         "https://trmads.eu/get?token=e45a87c8150da06004ff7e01bd2940a0&ptoken=6ek6ljudru8a3umtrr77duyyfkgi2b6y16w4gd4y",
	//         "https://trmads.eu/get?token=e45a87c8150da06004ff7e01bd2940a0&ptoken=f9dt2azkd7mzd7k91tcmp0mfzlib5k377ghormq0"
	//     ];
	// } else if (props.publication === 'lr') {
	//     transmaticoScript = [
	//         "https://trmads.eu/get?token=ul0u5frxa88hgx2247lbux8f0sqoxgwe&ptoken=i3s8af8qasdg245frnuvw4gy0v4bqs2f66iwbnfd",
	//         "https://trmads.eu/get?token=ul0u5frxa88hgx2247lbux8f0sqoxgwe&ptoken=6ek6ljudru8a3umtrr77duyyfkgi2b6y16w4gd4y",
	//         "https://trmads.eu/get?token=ul0u5frxa88hgx2247lbux8f0sqoxgwe&ptoken=f9dt2azkd7mzd7k91tcmp0mfzlib5k377ghormq0"
	//     ];
	// }
	//
	// useHead({
	//     script: [
	//         { src: transmaticoScript[0], async: true },
	//     ]
	// });
	//
	// if (!isTransmaticoScriptLoaded.value) {
	//     useHead({
	//         script: [
	//             { src: transmaticoScript[1] },
	//             { src: transmaticoScript[2] },
	//         ],
	//         link: [
	//             {
	//                  rel: "stylesheet",
	//                  href: transmaticoScript[3]
	//              },
	//         ]
	//     });
	//
	//     isTransmaticoScriptLoaded.value = true;
	// }
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-transmatico {
		margin: 0 auto;
		width: 100%;
		max-width: 100%;

		.transmaticoBox {
			width: 100%;
			max-width: 74.5rem;
			height: 100%;
			min-height: 90px;
		}
	}
</style>
