<template>
	<MoleculesSection :title="title" :sectionLinks="sectionLinks" :cleverPushChannelId="cleverPushChannelId">
		<div :class="['u-teaser-grid', `u-teaser-grid--layout-${layout}`]" :style="`--teaser-count:${teaserCount}`">
			<div class="u-teaser-grid__teaser-wrapper" v-for="(item, index) in adjustedItems" :key="index">
				<MoleculesTeaser v-bind="item.teaser" v-if="item.teaser" :theme="theme"></MoleculesTeaser>
				<div class="u-teaser-grid__ad-wrapper" v-if="item.ad">
					<MoleculesAdSlot v-bind="item.ad"></MoleculesAdSlot>
				</div>
			</div>
		</div>
	</MoleculesSection>
</template>

<script lang="ts" setup>
	import { computed } from "vue";
	import MoleculesTeaser, { type ITeaserProps } from "../molecules/MoleculesTeaser.vue";
	import MoleculesAdSlot, { type IAdProps } from "../molecules/MoleculesAdSlot.vue";
	import MoleculesSection from "../molecules/MoleculesSection.vue";

	export type TeaserGroupLayout = "1" | "3" | "4" | "6" | "8" | "9" | "10" | "11";

	export interface ITeaserGroupProps {
		teasers: ITeaserProps[];
		layout?: TeaserGroupLayout;
		title?: string;
		sectionLinks?: {
			label?: string;
			title?: string;
			url?: string;
			target?: string;
		}[];
		theme?: "light" | "dark";
		cleverPushChannelId?: string;
	}

	interface ITeaserGroupItem {
		teaser?: ITeaserProps;
		ad?: IAdProps;
	}

	const props = withDefaults(defineProps<ITeaserGroupProps>(), {
		layout: "1",
	});

	const adjustedItems = computed(() => {
		let items: ITeaserGroupItem[] = [];

		if (props.layout === "1") {
			items = props.teasers.map((teaser, index) => {
				return {
					teaser: {
						...teaser,
						layout: index === 0 ? "1" : "11",
					},
				};
			});
		} else if (props.layout === "3" || props.layout === "4" || props.layout === "6") {
			items = props.teasers.map((teaser, index) => {
				return {
					teaser: {
						...teaser,
						layout: index === 0 ? "2" : "3",
					},
				};
			});
		} else if (props.layout === "8") {
			items = props.teasers.map((teaser) => {
				return {
					teaser: { ...teaser, layout: "8" },
				};
			});
		} else if (props.layout === "9") {
			items = props.teasers.map((teaser, index) => {
				return {
					teaser: { ...teaser, layout: index === 0 ? "1" : "5" },
				};
			});
		} else if (props.layout === "10") {
			items = props.teasers.map((teaser, index) => {
				return {
					teaser: { ...teaser, layout: "9" },
				};
			});
		}

		return items;
	});

	const teaserCount = computed(() => {
		return props.teasers?.length || 0;
	});
</script>

<style lang="scss">
	@import "../../assets/css/mixins";

	.u-teaser-grid {
		display: grid;
		grid-template-columns: 1fr;
	}

	.u-teaser-grid__teaser-wrapper {
		display: flex;

		> .u-teaser {
			width: 100%;
		}
	}

	.u-teaser-grid__ad-wrapper {
		display: flex;
		flex-grow: 1;
	}

	.u-teaser-grid--layout-1 {
		gap: calc(2 * var(--teaser-half-spacing));

		@include tablet-above {
			grid-template-columns: repeat(3, 1fr);
		}

		.u-teaser-grid__teaser-wrapper {
			@include mobile {
				&:where(:not(:last-child)) {
					@include spacer-horizontal;
				}
			}

			@include tablet-above {
				&:first-child {
					grid-column: 1 / span 3;

					.u-teaser--layout-1 .u-teaser__content {
						align-content: center;
					}
				}
				&:where(:first-child:not(:last-child)) {
					@include spacer-horizontal;
				}
				&:where(:not(:nth-child(3n + 1)):not(:last-child)) {
					@include spacer-vertical;
				}
			}

			.u-teaser--layout-1 .u-teaser-image {
				flex-grow: 1;
			}
		}
	}

	.u-teaser-grid--layout-3,
	.u-teaser-grid--layout-6 {
		gap: calc(2 * var(--teaser-half-spacing));

		@include tablet-above {
			grid-template-rows: auto auto 1fr;
		}

		@include tablet {
			grid-template-columns: repeat(2, 1fr);
		}

		@include desktop {
			grid-template-columns: repeat(3, 1fr);
		}

		.u-teaser-grid__teaser-wrapper {
			@include mobile {
				&:where(:not(:last-child)) {
					@include spacer-horizontal;
				}
			}

			@include tablet-above {
				&:first-child {
					grid-row: 1 / span 3;
					@include spacer-vertical();

					.u-teaser {
						align-self: flex-start;
					}
				}

				&:where(:not(:first-child):not(:last-child)) {
					@include spacer-horizontal();
				}

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {
					.u-teaser {
						align-self: flex-start;
					}
				}
			}

			@include desktop {
				&:first-child {
					grid-column: 1 / span 2;
				}
			}
		}
	}

	.u-teaser-grid--layout-4 {
		gap: calc(2 * var(--teaser-half-spacing));

		@include tablet-above {
			grid-template-columns: repeat(3, 1fr);
		}

		.u-teaser-grid__teaser-wrapper {
			@include mobile {
				&:where(:not(:last-child)) {
					@include spacer-horizontal;
				}
			}

			@include tablet-above {
				&:first-child {
					grid-column: 1 / span 3;

					.u-teaser {
						display: grid;
						grid-template-columns: repeat(3, 1fr);
						gap: var(--teaser-half-spacing);
					}

					.u-teaser__content {
						grid-column: span 2;
						align-content: center;
					}
				}

				&:first-child:not(:last-child) {
					@include spacer-horizontal();
				}

				&:where(:not(:first-child):not(:last-child)) {
					@include spacer-vertical();
				}

				.u-teaser--layout-3 {
					align-items: normal;
				}
			}
		}
	}

	.u-teaser-grid--layout-8 {
		gap: calc(2 * var(--teaser-half-spacing));

		@include tablet-above {
			grid-template-columns: repeat(3, 1fr);
		}

		.u-teaser-grid__teaser-wrapper {
			@include mobile {
				&:where(:not(:last-child)) {
					@include spacer-horizontal;
				}
			}

			@include tablet-above {
				&:where(:not(:nth-child(3n)):not(:last-child)) {
					@include spacer-vertical;
				}
			}
		}
	}

	.u-teaser-grid--layout-9 {
		gap: calc(2 * var(--teaser-half-spacing));

		@include tablet-above {
			grid-template-columns: repeat(3, 1fr);
		}

		.u-teaser-grid__teaser-wrapper {
			@include mobile {
				&:where(:not(:last-child)) {
					@include spacer-horizontal;
				}
			}

			@include tablet-above {
				&:first-child {
					grid-column: 1 / span 3;
				}
				&:where(:first-child:not(:last-child)) {
					@include spacer-horizontal;
				}
				// &:where(:not(:first-child):not(:last-child)) {
				// 	@include spacer-vertical;
				// }

				&:where(:not(:nth-child(3n + 1)):not(:last-child)) {
					@include spacer-vertical;
				}
			}
		}

		.u-teaser--layout-1 .u-base-image {
			flex-grow: 1;
		}
	}

	.u-teaser-grid--layout-10 {
		gap: calc(2 * var(--teaser-half-spacing));

		.u-teaser-grid__teaser-wrapper {
			&:where(:not(:last-child)) {
				@include spacer-horizontal;
			}
		}
	}
</style>
