<template>
	<ClientOnly>
		<div class="u-oembed-wrapper">
			<div v-if="isGranted" v-html="htmlToRender" ref="htmlRef"></div>
			<template v-if="isGranted">
				<component v-for="(scriptObject, index) in scriptsToLoad" :key="index" is="script" v-bind="scriptObject.attrs">
					{{ scriptObject.content }}
				</component>
			</template>

			<div :class="[{ 'u-oembed-socialmedia': !isGranted }, socialmedia]">
				<AtomsBaseHeadline heading="h4" class="u-oembed-socialmedia__headline lh-lg" v-if="isGranted !== true">
					Empfohlener Inhalt der Redaktion
				</AtomsBaseHeadline>
				<AtomsParagraph class="u-oembed-socialmedia__text" :text="socialMediaContent[socialmedia].text" v-if="isGranted !== true" />

				<AtomsToggleSwitch
					name="socialmediaToggle"
					v-if="fullInitialized"
					:modelValue="isGranted"
					@update:modelValue="toggle"
					class="u-oembed-socialmedia__toggle"
				>
					Externer Inhalt
				</AtomsToggleSwitch>

				<AtomsParagraph class="u-oembed-socialmedia__info" :text="socialMediaContent[socialmedia].info" v-if="isGranted !== true" />
				<div>Mehr dazu in unserer <AtomsBaseLink url="/privacy/">Datenschutzerklärung</AtomsBaseLink>.</div>
			</div>
		</div>
		<template #fallback>
			<div>
				<Loading style="height: 30rem"></Loading>
			</div>
		</template>
	</ClientOnly>
</template>

<script lang="ts" setup>
	import { ref, onMounted, computed } from "vue";
	import AtomsBaseHeadline from "../../storybook/src/components/atoms/AtomsBaseHeadline.vue";
	import AtomsParagraph from "../../storybook/src/components/atoms/AtomsParagraph.vue";
	import AtomsToggleSwitch from "../../storybook/src/components/atoms/AtomsToggleSwitch.vue";
	import AtomsBaseLink from "../../storybook/src/components/atoms/AtomsBaseLink.vue";

	interface OembedWrapperProps {
		socialmedia: string;
		html: string;
	}

	const props = withDefaults(defineProps<OembedWrapperProps>(), {
		html: "<div>Default Oembed Content</div>",
		socialmedia: "default",
	});

	const isGranted = ref<boolean>(false);
	const htmlRef = ref(null);
	const scriptRegex = /<script.+?\/script>/g;
	const htmlToRender = ref((props.html + "").replace(scriptRegex, ""));

	const scriptsToLoad = computed(() => {
		const matches = [...(props.html + "").matchAll(scriptRegex)];
		const scripts = matches.map((match) => {
			const str = match[0];
			const contentSearch = str.match(/>(.*?)</);

			return {
				content: contentSearch ? contentSearch[1] : "",
				attrs: Object.fromEntries(
					str
						// remove script tag from string to get only attributes
						.replace(/<script /, "")
						.replace(/>.*<\/script>/, "")
						// split attributes
						.split(" ")
						.map((s) => {
							// get key and value by regex
							const keyAndValue = s.match(/([^=]+)=?(.+)?/) || [];
							// prepare key
							const key = keyAndValue[1] || "";
							// prepare value
							const value = keyAndValue[2]?.replace(/^\\?\"/, "").replace(/\\?\"$/, "") || true;
							return [key, value];
						}),
				),
			};
		});

		return scripts;
	});

	const socialMediaContent: any = {
		default: {
			vendorId: Math.random().toString(16).slice(2),
			text: "An dieser Stelle finden Sie einen externen Inhalt, der den Artikel ergänzt. Sie können sich diesen mit einem Klick anzeigen lassen und wieder ausblenden.",
			info: "Sie erklären sich damit einverstanden, dass Ihnen externe Inhalte angezeigt werden. Damit können personenbezogene Daten an Drittplattformen übermittelt werden.",
		},
		facebook: {
			vendorId: "5e716fc09a0b5040d575080f",
			text: "An dieser Stelle finden Sie einen externen Inhalt von Facebook, der den Artikel ergänzt. Sie können sich diesen mit einem Klick anzeigen lassen und wieder ausblenden.",
			info: "Sie erklären sich damit einverstanden, dass Ihnen externe Inhalte von Facebook angezeigt werden. Damit können personenbezogene Daten an Drittplattformen übermittelt werden.",
		},
		twitter: {
			vendorId: "5e71760b69966540e4554f01",
			text: "An dieser Stelle finden Sie einen externen Inhalt von Twitter, der den Artikel ergänzt. Sie können sich diesen mit einem Klick anzeigen lassen und wieder ausblenden.",
			info: "Sie erklären sich damit einverstanden, dass Ihnen externe Inhalte von Twitter angezeigt werden. Damit können personenbezogene Daten an Drittplattformen übermittelt werden. ",
		},
		instagram: {
			vendorId: "5e7e5243b8e05c1c467daa57",
			text: "An dieser Stelle finden Sie einen externen Inhalt von Instagram, der den Artikel ergänzt. Sie können sich diesen mit einem Klick anzeigen lassen und wieder ausblenden.",
			info: "Sie erklären sich damit einverstanden, dass Ihnen externe Inhalte von Instagram angezeigt werden. Damit können personenbezogene Daten an Drittplattformen übermittelt werden.",
		},
		youtube: {
			vendorId: "5e7ac3fae30e7d1bc1ebf5e8",
			text: "An dieser Stelle finden Sie einen externen Inhalt von Youtube, der den Artikel ergänzt. Sie können sich diesen mit einem Klick anzeigen lassen und wieder ausblenden.",
			info: "Sie erklären sich damit einverstanden, dass Ihnen externe Inhalte von Youtube angezeigt werden. Damit können personenbezogene Daten an Drittplattformen übermittelt werden.",
		},
	};

	const vendorId = computed(() => socialMediaContent[props.socialmedia]?.vendorId || "");
	const fullInitialized = ref(false);
	let cmpListenTimer = null;

	async function toggle(data: boolean) {
		if (isGranted.value) {
			// save inner iframe because fb script doesnt rerender if toggle off and on again
			htmlToRender.value = htmlRef.value.innerHTML;
		}

		isGranted.value = data;

		if (fullInitialized.value && typeof window?.__tcfapi === "function") {
			if (isGranted.value && vendorId.value) {
				__tcfapi("postCustomConsent", 2, function () {}, [vendorId.value], [], []);
			}
		}
		window.localStorage.setItem(`hideVendor${vendorId.value}`, isGranted.value ? "false" : "true");
		const event = new CustomEvent(`hideVendor${vendorId.value}`, { detail: isGranted.value });
		document.body.dispatchEvent(event);
	}

	function checkGranted() {
		if (typeof window.__tcfapi === "function") {
			const hiddenByLocalStorage = window.localStorage.getItem(`hideVendor${vendorId.value}`) === "true";
			__tcfapi("getCustomVendorConsents", 2, function (data) {
				if (vendorId.value) {
					isGranted.value = !!data.consentedVendors.find((cv) => cv._id === vendorId.value) && !hiddenByLocalStorage;
				} else {
					isGranted.value = false;
				}
			});
		}
	}

	onMounted(() => {
		const hiddenByLocalStorage = window.localStorage.getItem(`hideVendor${vendorId.value}`);
		if (hiddenByLocalStorage !== "true") {
			checkGranted();
		}

		if (typeof window.__tcfapi === "function") {
			__tcfapi("addEventListener", 2, function () {
				clearTimeout(cmpListenTimer);
				cmpListenTimer = setTimeout(function () {
					checkGranted();
				}, 100);
			});
		}

		document.body.addEventListener(`hideVendor${vendorId.value}`, (event) => {
			if (isGranted.value !== event.detail) {
				isGranted.value = event.detail;
			}
		});

		fullInitialized.value = true;
	});
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-oembed-socialmedia {
		max-width: 500px;
		padding: var(--space-5) var(--space-9);
		border: 1px solid #e0e0e0;
		box-shadow: 0px 1.708px 5.125px 0px rgba(32, 32, 35, 0.1);
		background-color: rgba(255, 255, 255, 0.9);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: calc(100% - 100px);

		&.default {
			background-image: url("/static/images/embeds/star.svg");
		}
		&.facebook {
			background-image: url("/static/images/embeds/facebook.svg");
		}
		&.instagram {
			background-image: url("/static/images/embeds/instagram.svg");
		}
		&.twitter {
			background-image: url("/static/images/embeds/twitter.svg");
		}
		&.youtube {
			background-image: url("/static/images/embeds/youtube.svg");
		}

		@include desktop {
			padding: var(--space-7);
		}

		&__headline {
			margin-bottom: 0.5rem;
		}

		&__text {
			margin-bottom: 0.5rem;
		}

		&__info {
			margin-bottom: 1.8rem;
		}

		.u-oembed-socialmedia__toggle {
			margin: 1.25rem 0;
		}
	}

	.u-oembed-socialmedia__toggle {
		margin-top: 1.25rem;
		margin-bottom: 0.625rem;

		.u-switch__label {
			font-size: 1.125rem;
			font-weight: bold;
		}
	}

	.u-oembed-html-wrapper > iframe {
		// overwrite inline script
		width: 100% !important;
		height: 100% !important;
	}

	.instagram-media {
		// overwrite inline script
		position: relative !important;
	}
	.responsiveContainer > .instagram-media {
		// overwrite inline script
		position: absolute !important;
	}
</style>
