<template>
	<a :class="['u-teaser', `u-teaser--layout-${layout}`, 'text-color-black']" :href="url" @click="onClick" :data-id="id">
		<AtomsBaseImage class="u-teaser__image" :image="teaserImage" v-if="teaserImage?.url"></AtomsBaseImage>
		<div v-else class="u-teaser__image u-teaser__image--fallback"></div>
		<div
			class="u-teaser__content"
			:class="{
				'u-teaser__content--comment': showAuthor,
			}"
		>
			<h2 class="u-teaser__headline-wrapper">
				<span class="u-teaser__overline text-color-primary d-block fw-bold lh-sm" v-if="overline">{{ overline }}</span>
				<span class="visually-hidden" v-if="overline">: </span>
				<div class="u-teaser__headline">
					<AtomsPayPlusLogo
						class="u-teaser__plus-icon"
						:sourcePublication="currentPublication"
						:theme="theme"
						v-if="payCategory === 'plus'"
					></AtomsPayPlusLogo>
					<span v-html="teaserTitle"></span>
				</div>
			</h2>
			<img
				class="u-teaser__author-image"
				alt=""
				:src="authors[0].imageUrl"
				v-if="!!authors?.length && showAuthor && authors[0].imageUrl"
			/>
			<p class="u-teaser__description lh-lg" v-html="description" v-if="description"></p>
			<div class="u-teaser__meta-details fs-2">
				<span class="u-teaser__topic text-color-neutral-50" v-if="topicVisible && topic">{{ topic }}</span>
				<AtomsArticleTypeFlag :type="type" v-if="typeVisible"></AtomsArticleTypeFlag>
				<span
					class="u-teaser__author-name text-color-neutral-50"
					v-text="`Ein Kommentar von ${authors[0].name}`"
					v-if="!!authors?.length && showAuthor"
				></span>
				<span class="u-teaser__updated text-color-neutral-50" v-text="humanizedUpdatedDate" v-if="showUpdatedTime"></span>
				<span class="u-teaser__place text-color-neutral-50" v-text="place" v-if="showPlace"></span>
				<span
					class="u-teaser__reading-time text-color-neutral-50"
					v-text="`Lesedauer: ${readingTime} Min.`"
					v-if="showReadingTime"
				></span>
				<AtomsBookmark
					:type="'button'"
					class="u-teaser__bookmark-icon"
					:articleId="id"
					v-if="bookmarkVisible"
					:active="active"
					@toggleFunc="toggleBookmark"
				></AtomsBookmark>
			</div>
		</div>
	</a>
</template>

<script lang="ts" setup>
	import { computed } from "vue";
	import AtomsArticleTypeFlag, { type ArticleType } from "../atoms/AtomsArticleTypeFlag.vue";
	import AtomsBaseImage from "../atoms/AtomsBaseImage.vue";
	import AtomsBookmark from "../atoms/AtomsBookmark.vue";
	import AtomsPayPlusLogo from "../atoms/AtomsPayPlusLogo.vue";
	import type { IAuthorProps } from "./MoleculesAuthor.vue";

	export interface ITeaserProps {
		currentPublication?: SourcePublication;
		sourcePublication: SourcePublication;
		id: number | string;
		url: string;
		teaserTitle: string;
		type?: ArticleType;
		description?: string;
		overline?: string;
		payCategory?: "free" | "plus";
		place?: string;
		readingTime?: number;
		lastPublicationDate?: Date;
		teaserImage?: any;
		layout?: "1" | "2" | "3" | "5" | "7" | "8" | "9" | "11";
		authors?: IAuthorProps[];
		typeVisible?: boolean;
		topicVisible?: boolean;
		bookmarkVisible?: boolean;
		updatedTimeVisible?: boolean;
		placeVisible?: boolean;
		active?: boolean;
		topic?: string;
		theme?: "light" | "dark";
	}

	const props = withDefaults(defineProps<ITeaserProps>(), {
		layout: "1",
		type: "article",
		typeVisible: true,
		topicVisible: false,
		updatedTimeVisible: false,
		placeVisible: true,
		url: "",
	});

	const humanizedUpdatedDate = computed(() => {
		if (!props.lastPublicationDate) return;

		const durationThresholds = {
			day: 24 * 60 * 60 * 1000,
			hour: 60 * 60 * 1000,
			minute: 60 * 1000,
		};

		const lastPublicationDate = new Date(props.lastPublicationDate).getTime();
		const now = new Date().getTime();
		const duration = now - lastPublicationDate;

		if (duration >= durationThresholds.day) {
			const days = Math.round(duration / durationThresholds.day);
			return `vor ${days} ${days === 1 ? "Tag" : "Tage"}`;
		}
		if (duration >= durationThresholds.hour) {
			const hours = Math.round(duration / durationThresholds.hour);
			return `vor ${hours} Std.`;
		}
		if (duration >= durationThresholds.minute) {
			const minutes = Math.round(duration / durationThresholds.minute);
			return `vor ${minutes} Min.`;
		}
		return `vor 1 Min.`;
	});
	const showPlace = computed(() => {
		return props.place && props.placeVisible && ["article", "interview", "breaking", "liveticker", "podcast"].includes(props.type);
	});
	const showAuthor = computed(() => props.place && ["comment"].includes(props.type));
	const showReadingTime = computed(() => props.readingTime && ["explainer", "report"].includes(props.type));
	const showUpdatedTime = computed(() => props.lastPublicationDate && props.updatedTimeVisible);

	function toggleBookmark(state: boolean) {
		const event = new CustomEvent("bookmark-article", {
			detail: {
				state: state,
				id: props.id,
				title: props.teaserTitle,
				subtitle: props.overline,
				place: props.place,
				url: window.location.origin + window.location.pathname,
			},
		});
		document.body.dispatchEvent(event);
	}

	function onClick() {
		const event = new CustomEvent("teaser-click", {
			detail: {
				id: props.id,
			},
		});
		document.body.dispatchEvent(event);
	}
</script>

<style lang="scss">
	@import "../../assets/css/mixins";

	.u-teaser {
		text-decoration: none;
		display: flex;
		gap: 1.25rem;

		&:hover {
			color: var(--npg-theme-font-color);

			.u-teaser__headline {
				color: var(--color-primary-100);
			}
		}

		/* dont change color if bookmark icon is hovered */
		&:hover:has(.u-teaser__bookmark-icon:hover) {
			color: currentColor;

			.u-teaser__headline {
				color: currentColor;
			}
		}
	}

	.u-teaser__image {
		border-radius: var(--rounding-1);
		overflow: hidden;
		min-width: 33%;
		aspect-ratio: 16 / 9;

		@include desktop {
			border-radius: var(--rounding-1);
		}
	}

	.u-teaser__image--fallback {
		background: #f7f7f7;
	}

	.u-teaser__content {
		display: grid;
		grid-template-columns: 1fr auto;
		gap: 0 1.25rem;
	}

	.u-teaser__headline-wrapper {
		margin: 0;
	}

	.u-teaser__overline {
		font-size: var(--font-size-2);
		margin-bottom: var(--space-2);

		@include desktop {
			font-size: var(--font-size-4);
		}
	}

	.u-teaser__headline {
		font-size: var(--font-size-4);
		grid-column: 1 / span 1;
		transition: color 0.3s ease;

		@include desktop {
			font-size: var(--font-size-6);
			word-break: break-word;
		}
	}

	.u-teaser__content--comment .u-teaser__headline {
		font-style: italic;
	}

	.u-teaser__reading-time,
	.u-teaser__updated,
	.u-teaser__place {
		&:not(:first-child)::before {
			content: "•";
			color: var(--neutral-50);
			margin-inline: 0.5rem;
		}
	}

	[data-type="liveticker"] {
		+ .u-teaser__reading-time,
		+ .u-teaser__updated,
		+ .u-teaser__place {
			margin-left: 0.25rem;

			&::before {
				display: none;
			}
		}
	}

	.u-teaser__bookmark-icon {
		margin-left: auto;
	}

	.u-teaser__author-image {
		align-self: center;
		width: 4rem;
		height: 4rem;
		border-radius: 2rem;
		overflow: hidden;
		grid-column: 2;
		object-fit: cover;
	}

	.u-teaser__reading-time,
	.u-teaser__author-name {
		font-style: italic;
	}

	.u-teaser__description {
		grid-column: 1 / span 2;
		margin-block: 0.625rem;
	}

	.u-teaser__meta-details {
		display: flex;
		align-items: center;
		grid-column: 1 / span 2;
	}

	@mixin card-coloumn {
		flex-direction: column;
	}

	@mixin card-row {
		flex-direction: row;
	}

	@mixin style-default {
		// flex-wrap: wrap;
		// align-items: center;

		.u-base-image {
			flex-basis: 50%;
			flex-grow: 1;
		}

		.u-teaser__content {
			flex-basis: 50%;
			flex-grow: 1;
		}
	}

	@mixin style-small-horizontal($hideImage: false) {
		align-items: center;

		@if ($hideImage) {
			.u-teaser__image {
				display: none;
			}

			.u-teaser__content {
				grid-template-columns: 1fr;

				&--comment {
					grid-template-columns: 1fr auto;
					.u-teaser__meta-details {
						grid-column: 1 / -1;
					}
				}
			}

			.u-teaser__meta-details {
				grid-column: 1 / span 1;
			}
		} @else {
			.u-base-image {
				flex-shrink: 5;
				flex-basis: 10rem;
			}
		}

		.u-teaser__content {
			flex-shrink: 1;
			flex-grow: 1;
		}

		.u-teaser__description {
			display: none;
		}

		.u-teaser__meta-details {
			margin-top: 0.5rem;
		}
	}

	.u-teaser--layout-1 {
		@include card-coloumn;
		// @include mobile {
		// 	flex-direction: column;
		// }

		@include tablet-above {
			@include card-row;

			.u-teaser__image {
				flex-basis: 58.333%;
				flex-grow: 1;
			}

			.u-teaser__content {
				flex-basis: 41.777%;
				flex-grow: 1;
			}
		}

		@include desktop {
			@include card-row;

			.u-teaser__image {
				flex-basis: 50%;
				flex-grow: 1;
			}

			.u-teaser__content {
				flex-basis: 50%;
				flex-grow: 1;
				align-content: center;
			}
		}
	}

	.u-teaser--layout-2 {
		@include mobile {
			@include style-small-horizontal;
		}

		@include tablet-above {
			flex-direction: column;

			.u-base-image {
				flex-basis: 50%;
				flex-grow: 1;
			}

			.u-teaser__content {
				flex-basis: 50%;
				flex-grow: 1;
			}
		}
	}

	.u-teaser--layout-3 {
		@include mobile {
			@include style-small-horizontal;
		}

		@include tablet-above {
			@include style-small-horizontal(true);
		}
	}

	.u-teaser--layout-5 {
		@include style-small-horizontal(true);

		.u-teaser__content {
			flex-grow: 1;
		}
	}

	.u-teaser--layout-7 {
		@include mobile {
			@include style-small-horizontal(true);
		}

		@include tablet-above {
			@include style-small-horizontal;
		}
	}

	.u-teaser--layout-8 {
		flex-direction: column;

		.u-teaser__description {
			display: none;
		}

		.u-teaser__meta-details {
			margin-top: 0.625rem;
		}
	}

	.u-teaser--layout-9 {
		@include style-small-horizontal;

		.u-teaser__description {
			display: none;
		}

		.u-teaser__image {
			max-width: 17.1875rem;
			min-width: 8.75rem;
			width: 100%;
			flex-basis: initial;
		}
	}

	.u-teaser--layout-11 {
		flex-direction: column;
	}

	.u-teaser__plus-icon {
		margin-right: 0.3em;
		transform: translateY(0.2em);
	}

	.u-teaser__topic {
		font-size: var(--font-size-2);
		font-weight: 400;
		line-height: 1.25rem;
		display: inline-block;
		padding: 0.25rem 0.5rem;
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 0.25rem;
		margin-right: var(--space-1);
	}
</style>
