<template>
	<div v-if="title || (sectionLinks && sectionLinks.length > 0)" class="u-section__header">
		<a class="u-section__title-link" v-if="titleHref" :href="titleHref">
			<h2 v-if="title" v-html="title.toUpperCase()" class="u-section__title" :id="anchorID"></h2>
		</a>
		<h2 v-else-if="title" v-html="title.toUpperCase()" class="u-section__title" :id="anchorID"></h2>
		<div class="u-section__links--wrapper">
			<div class="u-section__links" v-if="sectionLinks && sectionLinks.length > 0">
				<a
					v-for="(item, index) in sectionLinks"
					:key="index"
					:href="item.url"
					:title="item.title"
					:target="item.target || '_self'"
					class="u-section__link"
					:class="[`u-section__link--${item.icon ? 'icon' : 'default'}`]"
				>
					<component v-if="item.icon" :is="item.icon" />
					<template v-else-if="item.label">{{ item.label }}</template>
				</a>
			</div>
			<div v-if="cleverPushChannelId" class="cleverpush-widget" :data-id="cleverPushChannelId"></div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref, computed } from "vue";

	interface ISectionTitleProps {
		title?: string;
		sectionLinks?: {
			label?: string;
			title?: string;
			url?: string;
			icon?: string;
			target?: string;
		}[];
		cleverPushChannelId?: string;
		titleHref?: string;
	}

	const props = withDefaults(defineProps<ISectionTitleProps>(), {});
	const anchorID = ref(normalizeString(props.title || ""));

	// aus microservices/shared/functions/router.ts übenommen
	function normalizeString(string: string): string {
		//Lowercase, replace whitespaces with -, replace duplicate - and / with single - and /
		//remove all special characters and map them to ascii equivalents
		string = string
			.replace(/[,]/g, "")
			.replace(/[äÄ]/g, "ae")
			.replace(/[öÖ]/g, "oe")
			.replace(/[üÜ]/g, "ue")
			.replace(/[ß]/g, "ss")
			.normalize("NFD");

		//Recursively normalize url
		while (true) {
			let newString = string.toLowerCase().replace(/\s+/g, "-").replace(/-+/g, "-").replace(/\/\/+/g, "-");

			if (string === newString) {
				break;
			}
			string = newString;
		}

		return string.replace(/[^0-9a-zA-Z$-_.+!*'()]/g, "");
	}
</script>

<style lang="scss">
	@import "../../assets/css/mixins";

	.u-section__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		border-bottom: 0.0625rem solid var(--color-black);
		padding-bottom: var(--space-2);
		margin-bottom: var(--space-6);
		gap: var(--space-3);
		align-items: center;
	}

	.u-section__title {
		margin-block: 0;
	}

	.u-section__title-link {
		text-decoration: none;
	}

	.u-section__links {
		display: flex;
		gap: var(--space-2);
		flex-wrap: wrap;
		align-items: center;
	}

	.u-section__link--default {
		transition: all 0.2s ease;
		background-color: var(--npg-theme-section-link-background-color);
		border-radius: 0.313rem;
		color: var(--npg-theme-section-link-font-color);
		display: inline-block;
		font-size: 0.75rem;
		padding: 0.25rem 0.75rem;
		text-decoration: none;

		&:hover {
			background-color: var(--npg-theme-section-link-background-color);
			color: var(--color-primary-100);
			text-decoration: none;
			transition: all 0.3s ease;
		}
	}
	.u-section__link--icon {
		width: 1.875rem;
		height: 1.875rem;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.25rem;
		color: var(--color-black);
	}

	.u-section__links--wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: var(--space-2);
	}
</style>
