<template>
	<label
		class="u-switch__wrapper"
		:class="{ 'u-switch__wrapper--error': isValidated && error, 'u-switch__wrapper--success': isValidated && !error }"
	>
		<span class="u-switch">
			<input
				type="checkbox"
				v-model="model"
				:name="name"
				:required="required"
				v-bind="{
					'aria-labelledby': hasSlotContent ? `switch-${name}` : null,
					'aria-label': hasSlotContent ? null : label,
				}"
				class="u-switch__input visually-hidden"
			/>
			<span :class="['u-switch__toggler']"></span>
		</span>
		<div v-if="hasSlotContent || !!label" :id="`switch-${name}`" class="u-switch__label">
			<slot>{{ label }}</slot>
		</div>
	</label>
</template>

<script setup lang="ts">
	import { computed, useSlots } from "vue";

	const model = defineModel({ default: false });
	const slots = useSlots();

	withDefaults(
		defineProps<{
			error?: boolean;
			label?: string;
			name?: string;
			required?: boolean;
			isValidated?: boolean;
		}>(),
		{
			error: false,
			required: false,
			isValidated: false,
		},
	);

	const hasSlotContent = computed(() => {
		const slot = slots?.default;
		return slot ? slot()?.length > 0 : false;
	});
</script>

<style lang="scss">
	.u-switch__wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: var(--space-4);
	}

	.u-switch__input {
		opacity: 0;
	}

	.u-switch__input:focus-visible + .u-switch__toggler {
		outline: 0.3125rem auto Highlight;
		outline: 0.3125rem auto -webkit-focus-ring-color;
	}

	.u-switch {
		--switch-width: 2.625rem;
		position: relative;
		display: inline-block;
		width: var(--switch-width);
		min-width: var(--switch-width);
		height: 1.375rem;
	}

	.u-switch__toggler {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--color-neutral-10);
		transition: 0.4s;
		border-radius: 0.6875rem;
	}
	.u-switch__wrapper--error {
		.u-switch__toggler {
			background-color: var(--color-secondary-300);
		}
		.u-switch__label {
			color: var(--color-secondary-300);
		}
	}
	.u-switch__wrapper--success {
		input:checked + .u-switch__toggler,
		.u-switch__toggler {
			background-color: var(--color-secondary-100);
		}
		.u-switch__label {
			color: var(--color-secondary-100);
		}
	}

	.u-switch__label {
		font-size: var(--switch-label-font-size, var(--font-size-1));
		display: inline-block;
		margin-top: 0.1875rem;
	}

	.u-switch__toggler:before {
		border-radius: 50%;
		position: absolute;
		content: "";
		height: 1.125rem;
		width: 1.125rem;
		left: 0.125rem;
		bottom: 0.125rem;
		background-color: white;
		transition: ease-in-out 0.4s;
	}

	input:checked + .u-switch__toggler {
		background-color: var(--color-primary-100);
	}

	input:focus + .u-switch__toggler {
		box-shadow: 0 0 0.0625rem var(--color-primary-100);
	}

	input:checked + .u-switch__toggler:before {
		transform: translateX(1.25rem);
	}
</style>
