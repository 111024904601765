<template>
	<component :is="tag" v-bind="wrapperAttrs">
		<slot>{{ label }}</slot>
	</component>
</template>

<script lang="ts" setup>
	import { computed, useSlots } from "vue";

	export interface IBaseCTAProps {
		disabled?: boolean;
		external?: false;
		label?: string;
		title?: string;
		type?: string;
		url?: string;
		big?: boolean;
		fullsize?: boolean;
		version?: "primary" | "default" | "link";
	}

	const props = withDefaults(defineProps<IBaseCTAProps>(), {
		version: "default",
		type: "button",
		big: false,
		fullsize: false,
	});

	const tag = computed(() => (props.url ? "a" : "button"));
	const wrapperAttrs = computed(() => {
		let attrs = {
			class: [
				"u-base-cta",
				`u-base-cta--version-${props.version}`,
				startsWithText.value,
				endsWithText.value,
				props.big && `u-base-cta--big`,
				props.fullsize && `u-base-cta--fullsize`,
			],
			disabled: props.url ? null : props.disabled,
		} as { href?: string; target?: string; rel?: string; title?: string; type?: string };

		if (props.url) {
			attrs = {
				...attrs,
				href: props.url,
				title: props.title,
			};
		} else {
			attrs = {
				...attrs,
				type: props.type,
			};
		}

		return attrs;
	});
	const slots = useSlots();
	const defaultSlot = slots.default?.() || false;

	const startsWithText = computed(() =>
		defaultSlot && defaultSlot[0].type.toString() == "Symbol(v-txt)" ? "u-base-cta--text-left" : "",
	);
	const endsWithText = computed(() =>
		defaultSlot && defaultSlot[defaultSlot.length - 1].type.toString() == "Symbol(v-txt)" ? "u-base-cta--text-right" : "",
	);
</script>

<style lang="scss">
	.u-base-cta {
		border-radius: var(--rounding-1);
		text-decoration: none;
		user-select: none;

		display: inline-flex;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
		font-size: var(--button-font-size, 1rem);
		font-weight: var(--button-font-weight, normal);
		line-height: var(--button-line-height, inherit);

		padding: var(--button-padding, var(--space-1));
		border-width: 0.0625rem;
		border-style: solid;
		cursor: pointer;

		min-height: 2.375em;
		min-width: 2.25em;

		transition:
			color 0.1s ease-in-out,
			background-color 0.1s ease-in-out,
			border-color 0.1s ease-in-out,
			box-shadow 0.1s ease-in-out;

		&[disabled] {
			cursor: initial;
		}

		&:focus-visible {
			outline: none;
			box-shadow: 0 0 0 0.2em rgba(0, 0, 0, 1.5);
		}

		&--version-default {
			color: var(--color-neutral-90);
			background-color: var(--color-white);
			border-color: var(--color-neutral-10);

			&:active {
				box-shadow: 0 0 0 0.2em rgba(43, 179, 208, 0.5);
			}

			&:hover {
				background-color: var(--color-primary-100);
				border-color: var(--color-primary-100);
				color: var(--color-white);
			}

			&[disabled] {
				background-color: var(--color-neutral-10);
				border-color: var(--color-neutral-10);
				box-shadow: none;
				color: var(--color-neutral-60);
			}
		}

		&--version-primary {
			color: var(--color-white);
			background-color: var(--color-primary-100);
			border-color: var(--color-primary-100);

			&:focus-visible {
				background-color: var(--color-primary-200);
				border-color: var(--color-primary-200);
				color: var(--color-white);
			}

			&:active {
				box-shadow: 0 0 0 0.2em rgba(43, 179, 208, 0.5);
			}

			&:hover {
				background-color: var(--color-primary-200);
				border-color: var(--color-primary-200);
				color: var(--color-white);
			}

			&[disabled] {
				background-color: var(--color-neutral-10);
				border-color: var(--color-neutral-10);
				box-shadow: none;
				color: var(--color-neutral-60);
			}
		}

		&--version-link {
			text-decoration: underline;
			appearance: none;
			background: none;
			border: 0;
			padding: 0;
			cursor: pointer;
			user-select: text;

			&:hover {
				text-decoration: none;
			}

			&:focus {
				outline: none;
			}

			&:focus-visible {
				outline: 0.3125rem auto Highlight;
				outline: 0.3125rem auto -webkit-focus-ring-color;
				outline-offset: 0.25rem;
			}
		}

		&--text-left {
			padding-left: var(--space-3);
		}

		&--text-right {
			padding-right: var(--space-3);
		}

		svg {
			height: 1.2em;
			width: auto;
		}

		&.fw-bold {
			font-weight: 700;
		}
	}

	.u-base-cta--big {
		--button-font-size: 1.125rem;
		--button-font-weight: bold;
		--button-padding: 1rem 1.5rem;
		--button-line-height: 1;
	}

	.u-base-cta--fullsize {
		width: 100%;
	}
</style>
