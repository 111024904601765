<template>
	<section class="u-weekli" v-if="region && publicationMapping[publication]">
		<span class="u-weekli__title" v-if="title">{{ title }}</span>
		<div class="u-weekli__container">
			<div
				:id="`weekli-widget-${getPublicationData('slot')}`"
				class="u-weekli__widget weekli-widget"
				style="position: relative; width: auto; height: 240px"
				:data-key="getPublicationData('key')"
				:data-slot="getPublicationData('slot')"
				:data-config="getPublicationData('config')"
				:data-host="getPublicationData('host')"
				:data-region="getRegion(region)"
			></div>
		</div>
	</section>
</template>

<script lang="ts" setup>
	const publication = usePublication() || "swp";
	const publicationMapping: any = {
		swp: {
			key: "9DA7D6B9-606B-41D3-8C71-58DABD5B41ED",
			slot: 973,
			config: 845,
			host: "swp.weekli.de",
		},
		moz: {
			key: "8C013D75-4FA2-441D-A9AC-2E9F49376CD6",
			slot: 965,
			config: 831,
			host: "moz.weekli.de",
		},
	};

	const props = withDefaults(
		defineProps<{
			title?: string;
			region?: string;
		}>(),
		{
			title: "Magazine & Prospekte",
			region: "ulm",
		},
	);

	const isWeekliScriptLoaded = useIsWeekliLoaded();

	function getRegion(region: string) {
		const diffrentRegionNames: any = {
			"neu-ulm": "ulm",
			"brandenburg-havel": "brandenburg-an-der-havel",
		};

		return region ? diffrentRegionNames[region] || region : "";
	}

	function getPublicationData(key: string) {
		return publicationMapping[publication][key];
	}

	if (!isWeekliScriptLoaded.value) {
		useHead({
			script: [{ src: "https://static-nt.weekli.systems/static/widget/widget-loader-1.6.1.min.js", defer: true }],
		});

		isWeekliScriptLoaded.value = true;
	}
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-weekli {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		flex: 0 0 100%;
		flex-basis: 0;
		flex-grow: 1;
		margin: 0 -15px;
		padding: 2rem;
		width: 100%;
		max-width: 100%;
		background-color: #f7f7f7;

		@include desktop {
			margin: 0;
		}

		&__title {
			padding: 0;
			font-size: 1.125rem;
			font-weight: 700;
			line-height: 1.5rem;
			letter-spacing: 0.03em;
			text-transform: uppercase;

			@include desktop {
				padding: 1rem 0;
				font-size: 1.25rem;
			}
		}

		&__container {
			padding: 1rem 0;
		}
	}
</style>
