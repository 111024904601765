<template>
	<div class="u-article-list">
		<OrganismsTeaserGroup v-bind="teasers" />
		<AtomsBaseCTA version="primary" @click="requestMoreArticles()" v-if="hasMoreArticles">weitere Inhalte nachladen</AtomsBaseCTA>
	</div>
</template>

<script setup lang="ts">
	import { ref } from "vue";
	import OrganismsTeaserGroup from "../../storybook/src/components/organisms/OrganismsTeaserGroup.vue";
	import { type ITeaserGroupProps } from "../../storybook/src/components/organisms/OrganismsTeaserGroup.vue";
	import AtomsBaseCTA from "../../storybook/src/components/atoms/AtomsBaseCTA.vue";

	export interface IArticleListProps {
		teaserGroup: ITeaserGroupProps;
		label: string;
		articleCount?: number;
		filter: {
			ressorts?: string[];
			audience?: string;
			plus?: boolean;
		};
	}

	const pageStore = usePageStore();
	const hasMoreArticles = ref<boolean>(true);

	const props = withDefaults(defineProps<IArticleListProps>(), {
		label: "weitere Inhalte nachladen",
	});

	const teasers = ref<ITeaserGroupProps>(props.teaserGroup);

	if (!props.filter?.ressorts.length && !props.filter?.audience) {
		hasMoreArticles.value = false;
	}

	//Deduplicate the ids of the teasers
	pageStore.addDepulicatedIds(teasers.value.teasers.map((teaser) => teaser.id));

	async function requestMoreArticles() {
		const { data, error } = await useFetch("/api/page/teaser", {
			method: "POST",
			body: {
				count: props.articleCount,
				depulicatedIds: pageStore.getDepulicatedIds,
				filter: {
					...props.filter,
				},
			},
		});

		if (data.value) {
			const reloadedTeasers = data.value.map((teaser) => {
				return { ...teaser, bookmarkVisible: true };
			});

			teasers.value.teasers = [...teasers.value.teasers, ...reloadedTeasers];
			pageStore.addDepulicatedIds(data.value.map((teaser) => teaser.id));
		}

		if (error.value) {
			hasMoreArticles.value = false;
		}
	}
</script>

<style lang="scss">
	.u-article-list {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: var(--space-4);
	}
</style>
