<template>
	<component :is="tag" class="u-headline">
		<span class="font-set-7 text-color-primary d-block" :class="[primary ? 'mb-1' : 'mb-2']" v-if="overline">
			{{ overline }}
			<span class="visually-hidden" v-if="showOverlineColon">: </span>
		</span>
		<slot></slot>
	</component>
</template>

<script lang="ts" setup>
	import { computed } from "vue";

	const props = withDefaults(
		defineProps<{
			primary?: boolean;
			overline?: string;
			heading?: string;
		}>(),
		{ primary: false, heading: "h1" },
	);

	const showOverlineColon = computed(() => {
		return props.overline !== "" && props.primary;
	});

	const tag = computed(() => {
		return ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(props.heading) >= 0 ? props.heading : "h2";
	});
</script>

<style lang="scss">
	@import "../../assets/css/mixins";

	.u-headline {
		margin: 0 0 0.5rem;
		font-family: "Roboto", sans-serif;
		font-weight: 700;
	}

	h1.u-headline {
		font-size: 1.675rem;
		line-height: 2.125rem;
	}

	h2.u-headline {
		font-size: 1.5rem;
		line-height: 2.5rem;
	}

	h3.u-headline {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	h4.u-headline {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	h5.u-headline {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	h6.u-headline {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	@include desktop {
		h1.u-headline {
			font-size: 2.75rem;
			line-height: 3rem;
		}

		h2.u-headline {
			font-size: 1.5rem;
			line-height: 2.5rem;
		}

		h3.u-headline {
			font-size: 1.125rem;
			line-height: 1.5rem;
		}

		h4.u-headline {
			font-size: 1.125rem;
			line-height: 1.5rem;
		}

		h5.u-headline {
			font-size: 1.125rem;
			line-height: 1.5rem;
		}

		h6.u-headline {
			font-size: 1rem;
			line-height: 1.25rem;
		}
	}
</style>
