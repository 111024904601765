<template>
	<ClientOnly v-if="showAds.getShowAds()">
		<MoleculesAdSlot
			v-if="adType === 'adslot'"
			:ad_id="ad_id"
			:ad_unit_small="ad_unit_small"
			:ad_unit_large="ad_unit_large"
			class="u-adSlot--placed"
		/>

		<OutBrain v-if="adType === 'outbrain'" :adId="ad_id ?? ''" :outbrain_code="outbrain_code" />

		<Transmatico v-if="adType === 'transmatico' && adRegion" :publication="usePublication()" :region="adRegion" />

		<Weekli v-if="adType === 'weekli' && adRegion" :region="adRegion" />
	</ClientOnly>
</template>

<script lang="ts" setup>
	import { ref } from "vue";
	import { useShowAds } from "~/composables/useShowAds";

	const props = withDefaults(
		defineProps<{
			ad_id?: string;
			ad_unit_small?: string;
			ad_unit_large?: string;
			outbrain_code?: string;
			region?: string;
		}>(),
		{
			ad_id: "",
			outbrain_code: "",
		},
	);

	const adRegion = ref<string>("");
	const adType = ref<string>("adslot");
	const outbrain_code = ref(props.outbrain_code);
	const ad_id = ref(props.ad_id)
	const showAds = await useShowAds();

	if (props?.ad_unit_large === "outbrain" || props?.ad_unit_small === "outbrain") {
		adType.value = "outbrain";
	}

	if (props?.ad_id.indexOf("transmatico") > -1) {
		adType.value = "transmatico";

		adRegion.value = props.region || "";
	}

	if (props?.ad_id.indexOf("weekli") > -1) {
		adType.value = "weekli";
		adRegion.value = props.region || "";
	}
</script>