<template>
	<img class="u-pay-plus" :src="plusLogoBySourcePublication[sourcePublication]?.[theme]" :alt="alt" />
</template>

<script lang="ts" setup>
	import { computed } from "vue";
	import SwpPlusLogo from "../../assets/images/swp/payCategoryPlusLogo.svg";
	import SwpPlusLogoDark from "../../assets/images/swp/payCategoryPlusLogoDark.svg";
	import MozPlusLogo from "../../assets/images/moz/payCategoryPlusLogo.svg";
	import MozPlusLogoDark from "../../assets/images/moz/payCategoryPlusLogoDark.svg";
	import LrPlusLogo from "../../assets/images/lr/payCategoryPlusLogo.svg";
	import LrPlusLogoDark from "../../assets/images/lr/payCategoryPlusLogoDark.svg";

	const plusLogoBySourcePublication: { [key: string]: any } = {
		swp: {
			light: SwpPlusLogo,
			dark: SwpPlusLogoDark,
		},
		moz: {
			light: MozPlusLogo,
			dark: MozPlusLogoDark,
		},
		lr: {
			light: LrPlusLogo,
			dark: LrPlusLogoDark,
		},
	};

	interface IAtomsPayPlusLogoProps {
		sourcePublication: SourcePublication;
		theme?: "light" | "dark";
	}

	const props = withDefaults(defineProps<IAtomsPayPlusLogoProps>(), { theme: "light" });

	const alt = computed(() => `${props.sourcePublication?.toUpperCase()}+`);
</script>

<style lang="scss">
	.u-pay-plus {
		height: 1.1em;
	}
</style>
