<template>
	<div class="u-personalizations">
		<OrganismsHeaderBanner v-bind="headerBanner" />

		<div class="u-personalizations__content">
			<ClientOnly>
				<AtomsBaseHeadline
					heading="h4"
					primary
					:class="`${location ? 'mb-7 text-center' : 'mb-4'} u-personalizations__headline`"
					v-if="!loading"
					>{{ headline }}</AtomsBaseHeadline
				>
				<AtomsParagraph
					v-if="!location && !loading"
					class="mb-7"
					text="hier finden Sie eine kuratierte Auswahl an Artikeln, die speziell auf Ihre Interessen abgestimmt sind."
				/>

				<UserRecommendationTab :showLead="false" v-if="isLoggedIn" @loading="loadingStatus" />

				<div class="text-center" v-if="isLoggedIn && !loading">
					<AtomsBaseHeadline heading="h4" primary class="mt-7 mb-6 text-center"
						>Entdecken Sie jetzt Ihren persönlichen Bereich!</AtomsBaseHeadline
					>
					<AtomsBaseCTA v-bind="myTopicsCTA" class="my-4" />
				</div>

				<div class="u-personalizations__loggedout" v-if="!isLoggedIn">
					<AtomsBaseCTA @click="useOpenRegistration" version="link" :fullsize="true">
						<AtomsBaseImage :image="loggedOutImage" />
					</AtomsBaseCTA>
				</div>
			</ClientOnly>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref, computed } from "vue";

	const isLoggedIn = useUserIsLoggedIn();
	const config = useClientSideConfig();
	const userDataCookie = useUserDataFromCookie();
	const loading = ref(false);

	const headerBanner = computed(() => {
		return {
			headerline: config.meinBereichHeadline,
			subline: config.meinBereichSubline,
		};
	});

	const myTopicsCTA = computed(() => {
		return {
			label: config.meinBereichHeadline,
			url: config.my_page_link,
			version: "primary",
			big: true,
		};
	});

	const { data: location } = await useFetch(`/api/no-cache/user/user-favourites/favourite-location`);

	const headline = computed(() => {
		if (isLoggedIn) {
			if (location.value) {
				return `Willkommen zurück auf ${usePublication().toUpperCase()}.de, ${userDataCookie?.firstname} ${userDataCookie?.lastname}!`;
			} else {
				return `Hallo ${userDataCookie?.firstname} ${userDataCookie?.lastname},`;
			}
		}

		return "Sehr geehrte Leserin, sehr geehrter Leser,";
	});

	const loggedOutImage = {
		url: "/static/images/amex.svg",
		altText: "Bitte loggen Sie sich ein.",
		sources: {
			desktop: { url: "/static/images/MeineXYZ_OhneAnmeldung_Desktop.webp" },
			mobile: { url: "/static/images/MeineXYZ_OhneAnmeldung_Mobile.webp" },
		},
	};

	function loadingStatus(event: boolean) {
		loading.value = event;
	}
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/mixins";

	.u-personalizations {
		max-width: none;

		&__content {
			padding: var(--space-5);
			background-color: var(--color-primary-10);

			@include desktop {
				padding: var(--space-7);
			}
		}

		&__headline {
			text-align: center;

			@include desktop {
				text-align: left;

				&.text-center {
					text-align: center;
				}
			}
		}
	}
</style>
