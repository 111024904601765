<template>
	<component
		:is="tag"
		:class="['u-bookmark', isActive && 'is-active']"
		:aria-hidden="type === 'presentation' ? 'hidden' : null"
		:data-type="type"
		@click.stop.prevent="toggle"
	>
		<AtomsIconBookmark></AtomsIconBookmark>
	</component>
</template>

<script lang="ts" setup>
	import { computed, onMounted, onUnmounted, ref } from "vue";
	import AtomsIconBookmark from "../atoms/AtomsIconBookmark.vue";

	export type BookmarkIconTypes = "button" | "presentation";

	const emit = defineEmits(["toggleFunc"]);

	const props = withDefaults(
		defineProps<{
			active: boolean;
			type?: BookmarkIconTypes;
			articleId?: number;
			toggleFunc?: (isActive: boolean) => void;
		}>(),
		{
			active: false,
			type: "button",
		},
	);

	const isActive = ref(props.active);

	function toggle() {
		if (!isActive.value) {
			document.body.dispatchEvent(new CustomEvent("bookmark-added"));
			document.body.dispatchEvent(new CustomEvent("avo-bookmark-added"));
		}
		emit("toggleFunc", !isActive.value);
	}

	const tag = computed(() => {
		return props.type === "button" ? "button" : "div";
	});

	function updateBookmark(event: any) {
		const fixedArticleID = parseInt(props.articleId?.toString() || "-1");
		isActive.value = event.detail.includes(fixedArticleID);
	}

	onMounted(() => {
		document.body.addEventListener("update-bookmarks", updateBookmark);
		document.body.dispatchEvent(new CustomEvent("bookmark-mounted"));
	});

	onUnmounted(() => {
		document.body.removeEventListener("update-bookmarks", updateBookmark);
	});
</script>

<style lang="scss">
	.u-bookmark {
		color: var(--npg-theme-primary-color);
		appearance: none;
		background: none;
		border: 0;
		padding: 0;
		width: 2rem;
		height: 2rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg {
			width: 1rem;
		}

		&.is-active path {
			fill: currentColor;
		}
	}

	.u-bookmark[data-type="button"] {
		cursor: pointer;
		width: auto;

		&:hover path {
			fill: currentColor;
		}
	}
</style>