<template>
	<span class="u-article-type-flag" :data-type="type" v-text="visibleLabel" v-if="visibleLabel"></span>
</template>

<script lang="ts" setup>
	import { computed } from "vue";

	export type ArticleType =
		| "article"
		| "comment"
		| "interview"
		| "liveticker"
		| "breaking"
		| "explainer"
		| "report"
		| "podcast"
		| "data-analysis";

	interface IArticleTypeFlagProps {
		type?: ArticleType;
	}

	const props = withDefaults(defineProps<IArticleTypeFlagProps>(), {});
	const visibleLabel = computed(() => {
		switch (props.type) {
			case "article":
				return "";
			case "comment":
				return "";
			case "interview":
				return "Interview";
			case "liveticker":
				return "Live";
			case "breaking":
				return "Eilmeldung";
			case "explainer":
				return "Hintergrund";
			case "report":
				return "Reportage";
			case "podcast":
				return "Podcast";
			case "data-analysis":
				return "Datenanalyse";

			default:
				break;
		}
	});
</script>

<style lang="scss">
	.u-article-type-flag {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.03125em;
		display: inline-block;
		white-space: nowrap;

		&[data-type="explainer"],
		&[data-type="report"],
		&[data-type="podcast"] {
			background-color: var(--color-neutral-70);
			border-radius: 0.25em;
			color: var(--color-neutral-00);
			font-style: normal;
			padding: 0.25em 0.5em;
		}

		&[data-type="interview"] {
			color: var(--color-primary-100);
		}

		&[data-type="breaking"],
		&[data-type="liveticker"] {
			color: var(--color-secondary-300);
		}

		&[data-type="liveticker"] {
			&::after {
				content: "";
				background-image: url("@/assets/images/icon-live-ping.svg");
				height: 1.2em;
				width: 1.2em;
				display: inline-block;
				vertical-align: bottom;
				margin-left: 0.2em;
				background-size: contain;
				transform: translateY(0.075em);
			}
		}

		&[data-type="podcast"] {
			background-color: var(--color-primary-100);
			color: var(--color-neutral-00);

			&::before {
				content: "";
				background-image: url("@/assets/images/icon-podcast.svg");
				background-size: cover;
				margin-right: 0.5em;
				transition: transform 0.4s ease-out;
				height: 1.1em;
				position: relative;
				width: 1.1em;
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center center;
				vertical-align: bottom;
				transform: translateY(-0.1em);
			}
		}

		&[data-type="data-analysis"] {
			// Color seems missing in theme.
			color: #404040;
		}
	}
</style>
